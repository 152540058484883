<template>
  <v-dialog v-model="dialog" persistent max-width="450px">
    <v-card :disabled="loading" :loading="loading">
      <v-card-title>
        {{ $t("time.overtime_approval.overtime_approval") }}
        <v-spacer />
        <v-btn depressed icon @click="onClickCancel">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pt-3" v-if="overtime">
        <v-flex xs12 mt-2>
          <p v-html="approvalMessage" />
        </v-flex>
        <v-flex xs12>
          <v-text-field v-model.trim="overtimeApprovalDTO.note" name="note" :label="$t('global.note')" />
        </v-flex>
        <v-flex xs12>
          <v-radio-group row mandatory v-model="overtimeApprovalDTO.approved">
            <v-radio color="primary" :value="false" :label="$t('leave.request.denied')" />
            <v-radio color="primary" :value="true" :label="$t('leave.request.approved')" />
          </v-radio-group>
        </v-flex>
      </v-card-text>
      <v-card-actions class="dialog-footer">
        <v-spacer></v-spacer>
        <v-btn depressed @click.stop="onClickSave">{{ $t("buttons.save") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: "ConfirmDialog",
    props: {
      value: Boolean,
      data: Object,
      status: Boolean
    },
    data: () => ({
      dialog: false,
      loading: false,
      overtime: null,
      overtimeApprovalDTO: {
        approved: false,
        note: null
      }
    }),
    computed: {
      approvalMessage() {
        let shiftType = "";
        const overtime = this.overtime.overtime;
        const workplan = overtime.workplan;
        if (workplan.holiday && workplan.holiday.countAsOvertime) {
          shiftType = this.$t("time.overtime_approval.shift_type.holiday");
        } else if (workplan.breakDay || workplan.assignedShifts.length === 0) {
          shiftType = this.$t("time.overtime_approval.shift_type.offday");
        } else if (["FIX", "FLEXIBLE"].includes(workplan.assignedShifts[0].shiftType)) {
          shiftType = this.$t("time.overtime_approval.shift_type.workday");
        }
        return this.overtime
          ? this.$t("time.overtime_approval.approval_message", [
              overtime.employee.firstName + " " + overtime.employee.lastName,
              this.$helpers.formattedDate(workplan.date),
              overtime.overtimeInMinutes,
              shiftType
            ])
          : "";
      }
    },
    watch: {
      value: {
        handler(val) {
          this.dialog = val;
          this.overtimeApprovalDTO.approved = !!this.status;
          if (val) {
            this.overtime = { ...this.data };
          }
        },
        immediate: true
      }
    },
    methods: {
      onClickCancel() {
        this.overtimeApprovalDTO.approved = false;
        this.overtimeApprovalDTO.note = null;
        this.$emit("close");
      },
      onClickSave() {
        this.loading = true;
        this.$api.overtimeService
          .approve(this.overtime.id, this.overtimeApprovalDTO)
          .then(data => {
            if (data) {
              this.$emit("success");
              this.onClickCancel();
            }
          })
          .catch(console.error)
          .then(() => (this.loading = false));
      }
    }
  };
</script>
